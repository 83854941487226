import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'
import { Card, Flex, Box, Text } from 'theme-ui'
/* import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image' */

export default () => {

/*   const styles = {
    imageWrapper: ({ width, height }) => ({
      width: `full`,
      width: `1000px`,
      height: '400px',
      borderRadius: `default`,
      overflow: `hidden`
    }),
    image: {
      float: 'right',
      flexWrap: 'wrap'
    },

  } */

  const authors = useBlogAuthors()
  /* const data = useStaticQuery(teamImage)
  const { banner } = data
  const { fluid } = (banner && banner.childImageSharp) || {}
  if (!fluid) return null */

  return (
    <>
      <Seo title='O nas' />
      <Divider />
      <Stack effect='fadeInDown'>
        <PageTitle
          header='O nas'
          subheader='Witamy serdecznie na naszym blogu!'
        />
      </Stack>
      <Stack>
      <Card variant='paper'>
        <Flex>
          <Box>
          <Text variant='p'>
            <p>Witamy serdecznie na naszym blogu!</p>
            <p>W Paragrafie to blog prawniczy, skupiający się w swojej tematyce na szeroko rozumianym prawie nieruchomości. Naszym celem jest opisanie w sposób prosty i przejrzysty tego, co może wydawać się skomplikowanie i niejasne na pierwszy rzut oka. Teksty powstają na podstawie praktycznych doświadczeń, gromadzonych w codziennej pracy. Redakcja treści blogowych ma służyć również jego autorom w podnoszeniu swoich kwalifikacji i pozostawania na bieżąco z prawem nieruchomości. </p>
            <p>Piszemy o ciekawych tematach związanych z nieruchomościami, orzeczeniach sądów polskich i unijnych, wprowadzanych i planowanych zmianach przepisów, które mogą mieć przełożenie na obrót nieruchomościami czy proces inwestycyjny. W zakładce po godzinach można nas poznać z nieco innej, mniej prawniczej strony. </p>
            <p>Zapraszamy do śledzenia bloga, zamieszczania komentarzy lub propozycji tematów, o których chcieliby Państwo przeczytać. </p>
        </Text>
        {/* < Img fluid={fluid} sx={styles.image}/> */}
          </Box>

        </Flex>
        
      </Card>
      </Stack>
      <Stack>
        <Main>
          {authors.sort((a, b) => a.orderId > b.orderId ? 1 : -1)
          .map(author => (
            <>
              <Divider />
              <AuthorExpanded author={author} withLink/>
            </>
          ))}
        </Main>
      </Stack>
    </>
  )
}
/* 
const teamImage = graphql`
query eamImage {
  banner: file(absolutePath: { regex: "/team.(jpeg|jpg|gif|png)/" }) {
    childImageSharp {
      fluid(maxWidth: 200, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid_noBase64
        width: presentationWidth
        height: presentationWidth
      }
    }
  }
} 
`*/
  



